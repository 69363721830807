import React from "react";
import Container from "../components/container";
import Footer from "../components/footer";
import Nav from "../components/nav";

import "../styles/contact.scss";

import contactDivider from "../images/contactdivider.svg";

export default function Contact() {
  return (
    <main className="dark">
      <Nav />
      <div className="contact">
        <Container>
          <div className="contact__content">
            <div className="contact__content__header">
              <h1>Let’s Create Something Together</h1>
              <p>
                From strategy and research to development and testing, I’m
                passionate about pouring my ideas and skills into your desired
                product.
              </p>
            </div>
            <div className="contact__content__divider">
              <img src={contactDivider} alt="contact divider" />
            </div>

            <div className="contact__email">
              <p className="text-upper">SEND A MAIL</p>
              <p>email.yahoo.com</p>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </main>
  );
}
